import axios from "axios";

export default async function getData({ pageNum, pageSize, openid }) {
  return new Promise(resolve => {
    if (!openid) {
      return resolve({
        rows: [],
        total: 0
      });
    }
    axios
      .request({
        url: "/api/photo/photos",
        method: "post",
        baseURL: "https://api.game.zl2020.com",
        data: {
          pageNum,
          pageSize,
          openid
        }
      })
      .then(
        res => {
          const { code, msg, ...rest } = res.data;
          if (code === 200) {
            // const _rows = rest.rows || [];
            // const _rows = new Array(10).fill(null).map((item, index) => ({
            //   createTime: "2023-09-09 17:22:32",
            //   id: index + 1,
            //   openid: "o6bmjrPTIm6_2sgVt7hMZOPfL2M",
            //   deviceId: 3,
            //   uniqueCode: "000000000日",
            //   imgUuid: "25a4355a7b2e40edbb6a9465c4e8a611",
            //   imgUrl:
            //     "http://e.hiphotos.baidu.com/image/pic/item/a1ec08fa513d2697e542494057fbb2fb4316d81e.jpg"
            // }));
            // console.log("_rows", _rows);
            // const remainder = _rows.length % 3;
            // const remainderRow = remainder
            //   ? new Array(3 - remainder).fill(null)
            //   : [];
            // console.log("remainderRow", remainderRow);
            // resolve({
            //   total: 10,
            //   rows: [..._rows]
            // });
            resolve(rest);
          }
        },
        error => {
          console.log("get request failed:", error);
          resolve({
            rows: [],
            total: 0
          });
        }
      );
  });
}
