import { List, Empty } from "react-vant";
import "./Container.css";
import { useState } from "react";
import getData from "../api";
import Item from "../Item/Item";

const Container = () => {
  const [finished, setFinished] = useState(false);

  const [list, setList] = useState([]);

  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageNum: 1
  });
  const { pageSize, pageNum } = pagination;

  const onLoad = async () => {
    const url = new URL(window.location.href);
    const openid = url.searchParams.get("openid");

    const { rows, total } = await getData({ pageSize, pageNum, openid });

    console.log(rows, total);
    const _pageNum = pageNum + 1;

    if (total === 0 || rows.length < pageSize) {
      setFinished(true);
    }

    if (rows.length) {
      setList([...list, ...rows]);
    }

    setPagination({
      pageSize,
      pageNum: _pageNum,
      total
    });
  };

  return (
    <div id="container" className="container">
      <div className="tip">长按图片即可保存电子版至手机相册</div>
      <div className="content" id="content">
        <List className="r" finished={finished} onLoad={onLoad}>
          {list.map((item, index) => (
            <Item key={item.id} imgUrl={item.imgUrl} />
          ))}
        </List>
        {list.length === 0 ? <Empty description="暂无数据" /> : null}
      </div>
    </div>
  );
};

export default Container;
