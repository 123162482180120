import "./Item.css";

const Item = ({ imgUrl }) => {
  return (
    <div className="item">
      <img src={imgUrl} alt="" />
    </div>
  );
};

export default Item;
